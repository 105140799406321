<template>
  <Loader :show="loading"/>
  <HeaderHome/>
  <div class="container-fluid body">
    <div class="container">
      <div class="row body-box">
        <div class="col-md-8 col-md-offset-2">
          <h1>Contato</h1>
          <p class="text-justify  mb-4">Preencha o formulário abaixo para entrar em contato conosco,
            o mais breve possível retornaremos seu contato! Ou, se preferir,
            entre em contato através dos números: </p>
          <div class="row" style="display: flex;justify-content: center;">
            <div class="col-md-4 mb-4 text-center">Telefone: <a href="tel://1932386712" title="Telefone Fixo" target="_blank">(19) 3238-6712</a></div>
            <div class="col-md-4 text-center">Whatsapp: <a href="https://wa.me/5519991278218" title="Link Whatsapp" target="_blank">(19) 99127-8218</a></div>
          </div>

          <div class="container-fluid form-box">
            <form @submit.prevent="enviar">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Nome *</label>
                    <input type="text" class="form-control" required="" v-model="contato.nome">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>E-mail *</label>
                    <input type="email" class="form-control" required="" v-model="contato.email">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Assunto</label>
                    <input type="text" class="form-control" required="" v-model="contato.assunto">
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Mensagem</label>
                    <textarea rows="5" class="form-control" v-model="contato.mensagem"></textarea>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group  text-center">
                    <button type="submit" class="btn btn-success">Enviar</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <Atalhos/>
        </div>
      </div>
    </div>
  </div>
  <Newsletter/>
  <Footer/>
  <FooterEnd/>
</template>

<script>
import HeaderHome from "@/components/HeaderHome";
import Footer from "@/components/Footer";
import FooterEnd from "@/components/FooterEnd";
import Newsletter from "@/components/Newsletter";
import Atalhos from "@/components/Atalhos";
import {mask} from 'vue-the-mask'
import Loader from "../components/Loader";
import Swal from "sweetalert2";

export default {
  name: 'Contato',
  directives: {mask},
  components: {
    HeaderHome,
    Atalhos,
    Footer,
    FooterEnd,
    Newsletter,
    Loader
  },
  data() {
    return {
      step1: true,
      loading: false,
      contato: {
        nome: '',
        assunto: '',
        email: '',
        mensagem: ''
      }
    };
  },
  methods: {
    enviar() {
      console.log(this.contato);
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        Swal.fire({
          icon: 'success',
          title:'Contato foi enviado.',
          showConfirmButton: true,
        });

        this.contato = {
          nome: '',
          assunto: '',
          email: '',
          mensagem: ''
        };

      }, 2000);
    }
  },
};
</script>

<style>
label {
  margin-bottom: 15px;
}

.padding-h3 {
  padding: 10px 0 15px 0;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}
.mb-4{
  margin-bottom: 1.5rem;
}
.setup-panel a {
  color: #ac2925;
}
</style>
